import isEmpty from 'lodash/isEmpty';

/**
 * Removes null/undefined values from an object
 */

export default function compactObject(
  object,
  {removeFalsy = false, removeEmpty = false} = {},
) {
  return Object.keys(object).reduce((memo, key) => {
    let shouldKeep = removeFalsy ? object[key] : object[key] != null;

    if (removeEmpty && isEmpty(object[key])) {
      shouldKeep = false;
    }

    if (shouldKeep) {
      memo[key] = object[key];
    }
    return memo;
  }, {});
}
