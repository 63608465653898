import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import './Siteadmin.scss';
import App from './App.react';
import setupErrorTracking from './utils/error-tracking';

setupErrorTracking();

ReactDOM.render(
  <BrowserRouter basepath="/siteadmin">
    <App />
  </BrowserRouter>,
  document.getElementById('app'),
);
