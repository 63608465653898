import * as Sentry from '@sentry/browser';

const SENTRY_PUBLIC_DSN = process.env.SENTRY_PUBLIC_DSN;
const RELEASE = process.env.RELEASE;

export default function setupErrorTracking() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: SENTRY_PUBLIC_DSN,
      release: RELEASE,
      whitelistUrls: [/https?:\/\/((api|www)\.)?letrus\.com\.br/],
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          // Disables unhandled promise rejection reporting.
          //
          // TODO
          // Maybe we want to enable this later. Currently requests that are
          // handled by our redux promise middleware are firing sentry exceptions
          // and we want to clean-up the output a bit.
          onunhandledrejection: false,
        }),
      ],
    });
  }
}

export function configureSentryScope({id, email, username}) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope(scope => {
      scope.setUser({id, email, username});
    });
  }
}
