import AsyncSelect from 'react-select/async';
import {createFilter} from 'react-select';
import Bluebird from 'bluebird';
import React, {Component} from 'react';
import cookie from 'react-cookies';

import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import querystring from 'querystring';
import uniqBy from 'lodash/uniqBy';

import compactObject from './utils/compact-object';
import configureFetch from './utils/configure-fetch';

// TODO: change this to use variable
const fetch = configureFetch({serverUrl: process.env.SERVER_URL});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
};

export default class MassiveTestCreator extends Component {
  state = {
    schools: null,
    schoolIds: [],
    grades: null,
    gradesIds: [],
    schoolGroups: null,
    schoolGroupIds: [],
    schoolGroupsBySchool: [],
    testTemplates: null,
    testTemplateId: null,
    themes: null,
    themeId: null,
    activityName: null,
    startDatetime: null,
    endDatetime: null,
    messages: [],
    hasLoaded: false,
  };

  async componentWillMount() {
    const schools = await this.fetchSchools();
    const testTemplates = await this.fetchTestTemplates();
    const themes = await this.fetchThemes();
    this.setState({schools, testTemplates, themes, hasLoaded: true});
  }

  onChangeSchool = values => {
    this.setState(
      {
        schoolIds: values,
      },
      async () => {
        this.updateSchoolGroups();
      },
    );
  };

  onChangeGrades = e => {
    this.setState(
      {
        gradesIds: e,
      },
      async () => {
        this.updateSchoolGroups();
      },
    );
  };

  onChangeSchoolGroup = e => {
    this.setState({
      schoolGroupIds: e,
    });
  };

  onChangeTestTemplate = e => {
    this.setState(
      {
        testTemplateId: e,
        themeId: e.template.theme,
      },
      async () => {},
    );
    this.updateThemeId(e.template.theme);
  };

  onChangeTheme = e => {
    this.setState(
      {
        themeId: e,
      },
      async () => {},
    );
  };

  onChangeActivityName = e => {
    this.setState({
      activityName: e.target.value,
    });
  };

  onChangeStartDatetime = e => {
    this.setState({
      startDatetime: e.target.value,
    });
  };

  onChangeEndDatetime = e => {
    this.setState({
      endDatetime: e.target.value,
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    this.setState({
      messages: [],
    });

    const targetSchoolGroupIds = uniqBy(
      flatten(
        this.state.schoolGroupIds.concat(
          this.state.schoolIds.map(schoolId => {
            const schoolSchoolGroups =
              this.state.schoolGroupsBySchool[schoolId.value] || [];
            const selectedSchoolGroups = this.state.schoolGroupIds;
            const selectedSchoolGroupsById = groupBy(
              selectedSchoolGroups,
              'value',
            );

            const selectedSchoolSchoolGroups = schoolSchoolGroups
              ? schoolSchoolGroups.filter(schoolGroup => {
                  return selectedSchoolGroupsById[schoolGroup.value];
                })
              : [];

            const finalSchoolGroups = selectedSchoolSchoolGroups.length
              ? selectedSchoolSchoolGroups
              : schoolSchoolGroups;
            return finalSchoolGroups;
          }),
        ),
      ),
      'value',
    );

    await Bluebird.map(targetSchoolGroupIds, async schoolGroupId => {
      try {
        const res = await fetch(
          `/admin_api/v1/test_templates/${this.state.testTemplateId.value}/create_test/`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'x-csrftoken': cookie.load('csrftoken'),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: this.state.activityName,
              school_group_id: schoolGroupId.value,
              theme_id: this.state.themeId.value,
              start_datetime: this.state.startDatetime || new Date(),
              end_datetime: this.state.endDatetime || new Date(),
            }),
          },
        ).then(async res => {
          if (res.status >= 400) {
            throw await res.json();
          }
          return res.json();
        });

        this.setState(state => ({
          messages: state.messages.concat([
            {
              message: (
                <div className="alert alert-success">
                  <code>Atividade</code> criada para turma {schoolGroupId.value}
                  :{' '}
                  <a
                    href={`${process.env.SERVER_URL}/admin/compositions/test/${res.test}/change/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <code>Test {res.test} (link para admin)</code>
                  </a>
                </div>
              ),
            },
          ]),
        }));
      } catch (err) {
        this.setState(state => ({
          messages: state.messages.concat([
            {
              message: (
                <div className="alert alert-danger">
                  <code>A atividade</code> falhou em ser criada para a turma{' '}
                  {schoolGroupId.value}: <pre>{err.error}</pre>
                </div>
              ),
            },
          ]),
        }));
      }
    });
  };

  fetchCached = memoize(url => {
    return fetch(url).then(res => res.json());
  });

  fetchSchools = async inputValue => {
    const schools = await this.fetchCached(
      `/api/v1/schools/?${querystring.stringify({
        limit: 1000,
        ordering: '-id',
      })}`,
    );
    return schools.results
      .filter(school => {
        return inputValue
          ? school.long_name.toLowerCase().indexOf(inputValue.toLowerCase()) !==
              -1
          : true;
      })
      .map(school => ({
        value: school.id,
        label: school.long_name,
        school,
      }));
  };

  fetchSchoolGroups = async inputValue => {
    const schoolGroups = await this.fetchCached(
      `/api/v1/school_groups_list/?${querystring.stringify(
        compactObject({
          school_ids: this.state.schoolIds.map(({value}) => value),
          school_grade_ids: this.state.gradesIds.map(({value}) => value),
          current_year: true,
          limit: 1000,
        }),
      )}`,
    );

    return schoolGroups.results
      .filter(schoolGroup => {
        return inputValue
          ? schoolGroup.long_name
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          : true;
      })
      .map(schoolGroup => ({
        value: schoolGroup.id,
        label: schoolGroup.long_name,
        schoolGroup,
      }));
  };

  fetchGrades = async inputValue => {
    const schoolGroups = await this.fetchCached(
      `/api/v1/school_groups_list/?${querystring.stringify(
        compactObject({
          school_ids: this.state.schoolIds.map(({value}) => value),
          current_year: true,
          limit: 1000,
        }),
      )}`,
    );

    const uniqueGrades = uniqBy(schoolGroups.results, 'school_grade');

    return uniqueGrades
      .filter(schoolGroup => {
        return inputValue
          ? schoolGroup.school_grade
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          : true;
      })
      .map(schoolGroup => ({
        value: schoolGroup.school_grade_id,
        label: schoolGroup.school_grade,
      }));
  };

  fetchTestTemplates = async () => {
    const testTemplates = await this.fetchCached(
      `/admin_api/v1/test_templates/?${querystring.stringify({
        limit: 1000,
      })}`,
    );
    return testTemplates.results.map(template => ({
      value: template.id,
      label: template.name,
      template,
    }));
  };

  fetchThemes = async () => {
    const themes = await this.fetchCached(
      `/api/v1/composition_instructions/?${querystring.stringify({
        limit: 1000,
      })}`,
    );
    return themes.results.map(theme => ({
      value: theme.id,
      label: theme.title,
      theme,
    }));
  };

  updateGrades = async () => {
    const grades = await this.fetchGrades();
    this.setState({grades});
  };

  updateSchoolGroups = async () => {
    const schoolGroups = await this.fetchSchoolGroups();
    const grades = await this.fetchGrades();
    const schoolGroupsBySchool = groupBy(
      schoolGroups,
      group => group.schoolGroup.school_id,
    );
    this.setState({schoolGroups, schoolGroupsBySchool, grades});
  };

  updateThemeId = async id => {
    const selectedTheme = this.state.themes.filter(theme => theme.value == id);
    this.setState({themeId: selectedTheme[0]});
  };

  renderDetails = () => {
    return (
      <div>
        {this.state.schoolIds && this.state.schoolIds.length ? (
          <div>
            <strong>Criando atividade para as turmas:</strong>

            <ul>
              {this.state.schoolIds.map(schoolId => {
                const schoolSchoolGroups =
                  this.state.schoolGroupsBySchool[schoolId.value] || [];
                const selectedSchoolGroups = this.state.schoolGroupIds;
                const selectedSchoolGroupsById = groupBy(
                  selectedSchoolGroups,
                  'value',
                );

                const selectedSchoolSchoolGroups = schoolSchoolGroups
                  ? schoolSchoolGroups.filter(schoolGroup => {
                      return selectedSchoolGroupsById[schoolGroup.value];
                    })
                  : [];

                const finalSchoolGroups = selectedSchoolSchoolGroups.length
                  ? selectedSchoolSchoolGroups
                  : schoolSchoolGroups;

                return (
                  <span key={schoolId.value}>
                    <li>
                      {schoolId.label} ({schoolId.value})
                      {!selectedSchoolSchoolGroups.length && (
                        <div className="warning">
                          Ao não selecionar turmas, você está criando atividades
                          para todas as turmas dessa escola
                        </div>
                      )}
                      <ul>
                        {finalSchoolGroups.map(schoolGroup => {
                          return (
                            <li key={schoolGroup.value}>
                              {schoolGroup.label} ({schoolGroup.value})
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </span>
                );
              })}
            </ul>
          </div>
        ) : (
          <div>
            <strong>Selecione uma escola</strong>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <h1>Cadastro Massivo de Atividades</h1>

        <blockquote>
          <p>Formulário de criação massiva de atividades.</p>
        </blockquote>

        {this.state.hasLoaded ? (
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Escola</label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={this.state.schools}
                    loadOptions={this.fetchSchools}
                    onChange={this.onChangeSchool}
                    value={this.state.schoolIds}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                <div className="form-group">
                  <label>Série</label>
                  <AsyncSelect
                    isMulti
                    isDisabled={
                      this.state.schoolIds && !this.state.schoolIds.length
                    }
                    defaultOptions={this.state.grades}
                    onChange={this.onChangeGrades}
                    value={this.state.gradesIds}
                  />
                </div>

                <div className="form-group">
                  <label>Turma</label>
                  <AsyncSelect
                    isMulti
                    isDisabled={
                      this.state.gradesIds && !this.state.gradesIds.length
                    }
                    value={this.state.schoolGroupIds}
                    defaultOptions={this.state.schoolGroups}
                    onChange={this.onChangeSchoolGroup}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                <div className="form-group">
                  <label>Template da atividade</label>
                  <AsyncSelect
                    isDisabled={
                      this.state.gradesIds && !this.state.gradesIds.length
                    }
                    value={this.state.testTemplateId}
                    defaultOptions={this.state.testTemplates}
                    loadOptions={this.fetchTestTemplates}
                    onChange={this.onChangeTestTemplate}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                <div className="form-group">
                  <label>Tema</label>
                  <AsyncSelect
                    isDisabled={!this.state.testTemplateId}
                    value={this.state.themeId}
                    defaultOptions={this.state.themes}
                    loadOptions={this.fetchThemes}
                    onChange={this.onChangeTheme}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                <div className="form-group">
                  <label>Nome da atividade</label>

                  <input
                    type="text"
                    disabled={!this.state.testTemplateId}
                    onChange={this.onChangeActivityName}
                    className="form-control"
                    value={this.state.activityName}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Data de Inicio</label>

                  <input
                    type="datetime-local"
                    disabled={!this.state.testTemplateId}
                    onChange={this.onChangeStartDatetime}
                    className="form-control"
                    value={this.state.startDatetime}
                  />
                </div>

                <div className="form-group">
                  <label>Data de Fim</label>

                  <input
                    type="datetime-local"
                    className="form-control"
                    disabled={!this.state.testTemplateId}
                    onChange={this.onChangeEndDatetime}
                    value={this.state.endDatetime}
                  />
                </div>

                <button
                  className="btn btn-success"
                  type="submit"
                  disabled={
                    (this.state.schoolIds && !this.state.schoolIds.length) ||
                    (this.state.gradesIds && !this.state.gradesIds.length) ||
                    !this.state.testTemplateId ||
                    !this.state.themeId ||
                    !this.state.startDatetime ||
                    !this.state.endDatetime
                  }
                >
                  Criar
                </button>
              </form>
            </div>

            <div className="col-md-6">
              {this.renderDetails()}
              {this.state.messages.map(message => message.message)}
            </div>
          </div>
        ) : (
          <div>Carregando...</div>
        )}
      </div>
    );
  }
}
