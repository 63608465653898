/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import AsyncSelect from 'react-select/async';
import Select, {createFilter} from 'react-select';
import Bluebird from 'bluebird';
import React, {Component} from 'react';
import cookie from 'react-cookies';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import querystring from 'querystring';
import uniqBy from 'lodash/uniqBy';

import compactObject from './utils/compact-object';
import configureFetch from './utils/configure-fetch';

// TODO: change this to use variable
const fetch = configureFetch({serverUrl: process.env.SERVER_URL});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
};

export default class LearningPathInstanceCreator extends Component {
  state = {
    schools: null,
    schoolIds: [],
    grades: null,
    gradesIds: [],
    schoolGroupIds: [],
    schoolGroupsBySchool: [],
    learningPathTemplates: null,
    learningPathTemplate: null,
    titles: [],
    themes: null,
    themesIds: [],
    startDatetime: [],
    endDatetime: [],
    messages: [],
    hasLoaded: false,
    selectedTestType: {value: 'Normal', label: 'Normal'},
    testTypes: [
      {value: 'Normal', label: 'Normal'},
      {value: 'Substitutiva', label: 'Substitutiva'},
      {value: 'PRE', label: 'PRE'},
      {value: 'Dia Letrus', label: 'Dia Letrus'},
    ],
  };

  async componentWillMount() {
    const schools = await this.fetchSchools();
    const learningPathTemplates = await this.fetchLearningPaths();
    const themes = await this.fetchThemes();
    this.setState({schools, learningPathTemplates, themes, hasLoaded: true});
  }

  onChangeSchool = values => {
    this.setState(
      {
        schoolIds: values,
      },
      async () => {
        this.updateSchoolGroups();
      },
    );
  };

  onChangeGrades = e => {
    this.setState(
      {
        gradesIds: e,
      },
      async () => {
        this.updateSchoolGroups();
      },
    );
  };

  onChangeSchoolGroup = e => {
    this.setState({
      schoolGroupIds: e,
    });
  };

  onChangeLearningPath = e => {
    const themesFromLearningPath = e.learningPath.steps.map(step => ({
      value: step.test_template.theme.id,
      label: step.test_template.theme.title,
    }));
    this.setState({
      messages: [],
      learningPathTemplate: e,
      themesIds: themesFromLearningPath,
    });
  };

  onChangeTestType = e => {
    this.setState({
      selectedTestType: e,
    });
  };

  onChangeTitle = (e, step) => {
    let newTitles = this.state.titles;
    newTitles[step.order - 1] = e.target.value;
    this.setState({
      titles: newTitles,
    });
  };

  onChangeStartDatetime = (e, step) => {
    const newStartDatetime = this.state.startDatetime;
    newStartDatetime[step.order - 1] = e.target.value;
    this.setState({
      startDatetime: newStartDatetime,
    });
  };

  onChangeEndDatetime = (e, step) => {
    const newEndDatetime = this.state.endDatetime;
    newEndDatetime[step.order - 1] = e.target.value;
    this.setState({
      endDatetime: newEndDatetime,
    });
  };

  onChangeTheme = (e, step) => {
    const newThemesIds = this.state.themesIds;
    newThemesIds[step.order - 1] = {
      value: e.value,
      label: e.label,
    };
    this.setState({
      themesIds: newThemesIds,
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    this.setState({
      messages: [],
    });

    const targetSchoolGroupIds = uniqBy(
      flatten(
        this.state.schoolGroupIds.concat(
          this.state.schoolIds.map(schoolId => {
            const schoolSchoolGroups =
              this.state.schoolGroupsBySchool[schoolId.value] || [];
            const schoolGroupsById = groupBy(schoolSchoolGroups, 'value');
            const selectedSchoolGroups = this.state.schoolGroupIds;
            const selectedSchoolGroupsById = groupBy(
              selectedSchoolGroups,
              'value',
            );

            const selectedSchoolSchoolGroups = schoolSchoolGroups
              ? schoolSchoolGroups.filter(schoolGroup => {
                  return selectedSchoolGroupsById[schoolGroup.value];
                })
              : [];

            const finalSchoolGroups = selectedSchoolSchoolGroups.length
              ? selectedSchoolSchoolGroups
              : schoolSchoolGroups;
            return finalSchoolGroups;
          }),
        ),
      ),
      'value',
    );

    const selectedThemes = this.state.themesIds.map(theme => theme.value);

    await Bluebird.map(targetSchoolGroupIds, async schoolGroupId => {
      try {
        const res = await fetch(
          `/admin_api/v1/learning_path_templates/${this.state.learningPathTemplate.value}/create_instance/`,
          {
            method: 'POST',
            headers: {
              'x-csrftoken': cookie.load('csrftoken'),
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              school_group_id: schoolGroupId.value,
              test_type: this.state.selectedTestType.value,
              start_datetime: this.state.startDatetime || new Date(),
              end_datetime: this.state.endDatetime || new Date(),
              titles: this.state.titles || '',
              theme_ids: selectedThemes,
            }),
          },
        ).then(async res => {
          if (res.status >= 400) {
            throw await res.json();
          }
          return res.json();
        });

        this.setState(state => ({
          messages: state.messages.concat([
            {
              message: (
                <div className="alert alert-success">
                  <code>Trilha</code> criada para turma {schoolGroupId.value}:{' '}
                  <a
                    href={`${process.env.SERVER_URL}/admin/compositions/learningpathinstance/${res.id}/change/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <code>LearningPathInstance {res.id} (link para admin)</code>
                  </a>
                </div>
              ),
            },
            ...res.tests.map(test => ({
              message: (
                <div className="alert alert-info">
                  <code>Atividade</code> criada para turma {schoolGroupId.value}
                  :{' '}
                  <a
                    href={`${process.env.SERVER_URL}/admin/compositions/test/${test.id}/change/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <code>Test {test.id} (link para admin)</code>
                  </a>
                </div>
              ),
            })),
          ]),
        }));
      } catch (err) {
        this.setState(state => ({
          messages: state.messages.concat([
            {
              message: (
                <div className="alert alert-danger" key={schoolGroupId.value}>
                  <code>A trilha</code> falhou em ser criada para a turma{' '}
                  {schoolGroupId.value}: <pre>{err.error}</pre>
                </div>
              ),
            },
          ]),
        }));
      }
    });
  };

  fetchCached = memoize(url => {
    return fetch(url).then(res => res.json());
  });

  fetchSchools = async inputValue => {
    const schools = await this.fetchCached(
      `/api/v1/schools/?${querystring.stringify({
        limit: 1000,
        ordering: '-id',
      })}`,
    );
    return schools.results
      .filter(school => {
        return inputValue
          ? school.long_name.toLowerCase().indexOf(inputValue.toLowerCase()) !==
              -1
          : true;
      })
      .map(school => ({
        value: school.id,
        label: school.long_name,
        school,
      }));
  };

  fetchLearningPaths = async () => {
    const learningPaths = await this.fetchCached(
      `/admin_api/v1/learning_path_templates/?${querystring.stringify({
        limit: 1000,
      })}`,
    );
    return learningPaths.results.map(learningPath => ({
      value: learningPath.id,
      label: learningPath.name,
      learningPath,
    }));
  };

  fetchGrades = async inputValue => {
    const schoolGroups = await this.fetchCached(
      `/api/v1/school_groups_list/?${querystring.stringify(
        compactObject({
          school_ids: this.state.schoolIds.map(({value}) => value),
          current_year: true,
          limit: 1000,
        }),
      )}`,
    );

    const uniqueGrades = uniqBy(schoolGroups.results, 'school_grade');

    return uniqueGrades
      .filter(schoolGroup => {
        return inputValue
          ? schoolGroup.school_grade
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          : true;
      })
      .map(schoolGroup => ({
        value: schoolGroup.school_grade_id,
        label: schoolGroup.school_grade,
      }));
  };

  fetchThemes = async () => {
    const themes = await this.fetchCached(
      `/api/v1/composition_instructions/?${querystring.stringify({
        limit: 1000,
      })}`,
    );
    return themes.results.map(theme => ({
      value: theme.id,
      label: theme.title,
    }));
  };

  updateSchoolGroups = async () => {
    const schoolGroups = await this.fetchSchoolGroups();
    const grades = await this.fetchGrades();
    const schoolGroupsBySchool = groupBy(
      schoolGroups,
      group => group.schoolGroup.school_id,
    );
    this.setState({schoolGroups, schoolGroupsBySchool, grades});
  };

  fetchSchoolGroups = async inputValue => {
    const schoolGroups = await this.fetchCached(
      `/api/v1/school_groups_list/?${querystring.stringify(
        compactObject({
          school_ids: this.state.schoolIds.map(({value}) => value),
          school_grade_ids: this.state.gradesIds.map(({value}) => value),
          current_year: true,
          limit: 1000,
        }),
      )}`,
    );

    return schoolGroups.results
      .filter(schoolGroup => {
        return inputValue
          ? schoolGroup.long_name
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          : true;
      })
      .map(schoolGroup => ({
        value: schoolGroup.id,
        label: schoolGroup.long_name,
        schoolGroup,
      }));
  };

  renderDetails = () => {
    return (
      <div>
        {this.state.schoolIds && this.state.schoolIds.length ? (
          <div>
            <strong>Criando atividade para as turmas:</strong>

            <ul>
              {this.state.schoolIds.map(schoolId => {
                const schoolSchoolGroups =
                  this.state.schoolGroupsBySchool[schoolId.value] || [];
                const selectedSchoolGroups = this.state.schoolGroupIds;
                const selectedSchoolGroupsById = groupBy(
                  selectedSchoolGroups,
                  'value',
                );

                const selectedSchoolSchoolGroups = schoolSchoolGroups
                  ? schoolSchoolGroups.filter(schoolGroup => {
                      return selectedSchoolGroupsById[schoolGroup.value];
                    })
                  : [];

                const finalSchoolGroups = selectedSchoolSchoolGroups.length
                  ? selectedSchoolSchoolGroups
                  : schoolSchoolGroups;

                return (
                  <span key={schoolId.value}>
                    <li>
                      {schoolId.label} ({schoolId.value})
                      {!selectedSchoolSchoolGroups.length && (
                        <div className="warning">
                          Ao não selecionar turmas, você está criando atividades
                          para todas as turmas dessa escola
                        </div>
                      )}
                      <ul>
                        {finalSchoolGroups.map(schoolGroup => {
                          return (
                            <li key={schoolGroup.value}>
                              {schoolGroup.label} ({schoolGroup.value})
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </span>
                );
              })}
            </ul>
          </div>
        ) : (
          <div>
            <strong>Selecione uma escola</strong>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <h1>Criação de Trilhas Pedagógicas</h1>

        <blockquote>
          <p>Formulário de criação de trilhas pedagógicas.</p>
        </blockquote>

        {this.state.hasLoaded ? (
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Escola</label>
                  <AsyncSelect
                    isMulti
                    defaultOptions={this.state.schools}
                    loadOptions={this.fetchSchools}
                    onChange={this.onChangeSchool}
                    value={this.state.schoolIds}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                <div className="form-group">
                  <label>Série</label>
                  <AsyncSelect
                    isMulti
                    isDisabled={
                      this.state.schoolIds && !this.state.schoolIds.length
                    }
                    defaultOptions={this.state.grades}
                    onChange={this.onChangeGrades}
                    value={this.state.gradesIds}
                  />
                </div>

                <div className="form-group">
                  <label>Turma</label>
                  <AsyncSelect
                    isMulti
                    isDisabled={
                      this.state.gradesIds && !this.state.gradesIds.length
                    }
                    value={this.state.schoolGroupIds}
                    defaultOptions={this.state.schoolGroups}
                    onChange={this.onChangeSchoolGroup}
                  />
                </div>

                <div className="form-group">
                  <label>Trilha</label>
                  <AsyncSelect
                    isDisabled={!this.state.gradesIds.length}
                    value={this.state.learningPathTemplate}
                    defaultOptions={this.state.learningPathTemplates}
                    loadOptions={this.fetchLearningPaths}
                    onChange={this.onChangeLearningPath}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>
                <div className="form-group">
                  <label>Tipo</label>
                  <Select
                    isDisabled={!this.state.gradesIds.length}
                    defaultValue={this.state.selectedTestType}
                    options={this.state.testTypes}
                    onChange={this.onChangeTestType}
                    filterOption={createFilter(filterConfig)}
                  />
                </div>

                {this.state.learningPathTemplate &&
                  this.state.learningPathTemplate.learningPath.steps.map(
                    step => (
                      <div className="form-group" key={step.order}>
                        <div className="form-group">
                          <label>Título do passo {step.order}</label>

                          <input
                            type="text"
                            onChange={e => this.onChangeTitle(e, step)}
                            className="form-control"
                            value={this.state.titles[step.order - 1]}
                          />
                        </div>

                        <label>Data de Inicio do passo {step.order}</label>

                        <input
                          name={`startDatetime-step-${step.order}`}
                          type="datetime-local"
                          onChange={e => this.onChangeStartDatetime(e, step)}
                          className="form-control"
                          value={this.state.startDatetime[step.order - 1]}
                        />

                        <label>Data de Término do passo {step.order}</label>

                        <input
                          name={`endDatetime-step-${step.order}`}
                          type="datetime-local"
                          onChange={e => this.onChangeEndDatetime(e, step)}
                          className="form-control"
                          value={this.state.endDatetime[step.order - 1]}
                        />

                        <div className="form-group">
                          <label>Tema</label>
                          <AsyncSelect
                            isDisabled={
                              this.state.schoolIds &&
                              !this.state.schoolIds.length
                            }
                            value={this.state.themesIds[step.order - 1]}
                            defaultOptions={this.state.themes}
                            loadOptions={this.fetchThemes}
                            onChange={e => this.onChangeTheme(e, step)}
                            filterOption={createFilter(filterConfig)}
                          />
                        </div>
                      </div>
                    ),
                  )}

                <button
                  className="btn btn-success"
                  type="submit"
                  disabled={
                    !this.state.learningPathTemplate ||
                    (this.state.schoolIds &&
                      !this.state.schoolIds.length &&
                      this.state.schoolGroupIds &&
                      !this.state.schoolGroupIds.length)
                  }
                >
                  Criar
                </button>
              </form>
            </div>

            <div className="col-md-6">
              {this.renderDetails()}
              {this.state.messages.map(message => message.message)}
            </div>
          </div>
        ) : (
          <div>Carregando...</div>
        )}
      </div>
    );
  }
}
