import React, {Component} from 'react';
import cookie from 'react-cookies';
import fetch from 'isomorphic-fetch';
import {File, Form, Row} from 'formsy-react-components';
import {Link, Route} from 'react-router-dom';

import LearningPathInstanceCreator from './LearningPathInstanceCreator';
import MassiveTestCreator from './MassiveTestCreator';
import IuguCustomerTable from './IuguCustomers';

export function HomeMenu() {
  return (
    <div>
      <Link className="btn btn-success" to="/siteadmin/import-csv">
        Importar CSV
      </Link>
    </div>
  );
}

export class ImportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dryRun: true,
    };
  }

  logout() {
    return (window.location.href = '/logout_and_go');
  }

  submit(model) {
    const start = new Date().getTime();
    const dryRun = this.state.dryRun;
    const body = new FormData();
    body.append('csv_file', model.csv_file[0]);

    return fetch(`/api/v1/tests/import-csv/${dryRun ? '?dry-run=1' : ''}`, {
      headers: {
        'x-csrftoken': cookie.load('csrftoken'),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body,
      credentials: 'include',
    })
      .then(res => res.json())
      .then(result => {
        this.setState({
          result,
          // Keep running in dry-run mode until the result is ok
          dryRun: !result.ok,
        });

        if (result.ok && dryRun) {
          this.submit(model).then(() => {
            this.setState({
              elapsed: new Date().getTime() - start,
            });
          });
        }
      });
  }

  renderErrors() {
    if (this.state && this.state.result && !this.state.result.ok) {
      if (this.state.result.detail) {
        // if user not access
        return (
          <div>
            <hr />
            <div className="list-group">
              <div className="list-group-item">{this.state.result.detail}</div>
            </div>
          </div>
        );
      }
      return (
        <div>
          <hr />
          <h4>A importação falhou com os seguintes erros:</h4>
          <div className="list-group">
            {this.state.result.data
              .map((r, i) => [r, i])
              .filter(([r, i]) => !r.ok)
              .map(([r, i]) => (
                <div className="list-group-item" key={i}>
                  <strong>Linha {i}</strong>
                  <br />
                  {r.error === 'SchoolGroup.DoesNotExist' && (
                    <p>
                      Grupo de Escola:
                      <br />
                      {r.raw.school_group__school__long_name}
                      {r.raw.school_group__long_name}
                      <br />
                      Não existe
                    </p>
                  )}
                  {r.error === 'CompositionInstruction.DoesNotExist' && (
                    <p>
                      Tema de Redação:
                      <br />
                      {r.raw.theme__theme_id__title}
                      <br />
                      Não existe
                    </p>
                  )}

                  {
                    <p>
                      <pre>
                        <code>
                          {JSON.stringify(r.error, null, 2)}
                          {'\n\n'}
                          {JSON.stringify(r.raw, null, 2)}
                        </code>
                      </pre>
                    </p>
                  }
                </div>
              ))}
          </div>
        </div>
      );
    }
  }

  renderSuccess() {
    if (
      this.state &&
      !this.state.dryRun &&
      this.state.result &&
      this.state.result.ok
    ) {
      return (
        <div>
          <hr />
          <h4>
            {this.state.result.data.length} Testes importados em{' '}
            {this.state.elapsed}
            ms
          </h4>
          <div className="list-group">
            {this.state.result.data.map((r, i) => (
              <div className="list-group-item" key={i}>
                <strong>Linha {i}</strong>
                <br />
                <a target="_blank" href={`/admin/compositions/test/${r.id}`}>
                  Teste {r.id} criado com sucesso
                </a>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  render() {
    this.submit = this.submit.bind(this);

    return (
      <div>
        {this.renderErrors()}
        {this.renderSuccess()}

        <p>
          <Form horizontal onValidSubmit={this.submit}>
            <File
              name="csv_file"
              help="Selecione seu arquivo CSV com o formato descrito na documentação"
              label="Seu arquivo CSV"
            />
            <fieldset>
              <Row layout="horizontal">
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
              </Row>
            </fieldset>
          </Form>
        </p>
      </div>
    );
  }
}

export default class App extends Component {
  render() {
    return (
      <div>
        {/* <Header> */}
        <div className="navbar navbar-default">
          <div className="container-fluid">
            <ul className="navbar-left nav navbar-nav">
              <li>
                <a
                  href={
                    process.env.NODE_ENV === 'production'
                      ? `https://www.letrus.com.br/dashboard`
                      : '/dashboard'
                  }
                >
                  Letrus
                </a>
              </li>
              <li className="divider" role="separator" />
              <li>
                <a href={`${process.env.SERVER_URL}/admin`}>Django Admin</a>
              </li>
            </ul>
            <ul className="navbar-right nav navbar-nav">
              <li>
                <a href={`${process.env.SERVER_URL}/logout_and_go`}>Sair </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <TestingData> */}
        {process.env.NODE_ENV === 'development' ? (
          <div>
            <div className="container">
              <h1>Criar dados de teste</h1>

              <blockquote>
                <p>
                  Cada botão abaixo vai criar uma escola completa de teste com o
                  tipo de usuário indicado, algumas atividades, vários alunos e
                  te logar na plataforma.
                </p>
              </blockquote>

              <div className="btn-group">
                <a
                  className="btn btn-default"
                  href="/dev/create_student?contract_type=B2B"
                >
                  Novo aluno B2B
                </a>
                <a
                  className="btn btn-default"
                  href="/dev/create_student?contract_type=B2B2C"
                >
                  Novo aluno B2B2C
                </a>
                <a
                  className="btn btn-default"
                  href="/dev/create_student?contract_type=B2B2C&plan_type=student_advanced"
                >
                  Novo aluno B2B2C Convertido
                </a>
                <a className="btn btn-warning" href="/dev/create_reviewer">
                  Novo corretor
                </a>
                <a className="btn btn-primary" href="/dev/create_teacher">
                  Novo professor
                </a>
              </div>
            </div>
            <hr />
          </div>
        ) : null}
        {/* <LearningPath> */}
        <div className="container">
          <LearningPathInstanceCreator />
        </div>
        <hr />
        {/* <MassiveTest> */}
        <div className="container">
          <MassiveTestCreator />
        </div>
        <hr />
        {/* <IuguCustomers> */}
        <div className="container">
          <IuguCustomerTable />
        </div>
      </div>
    );
  }
}
