import * as nodeUrl from 'url';
import Cookie from 'cookies-js';
import Promise from 'bluebird';
import fetch from 'isomorphic-fetch';
import nprogress from 'nprogress';
import Debug from 'debug';

import './nprogress.scss';

const debug = Debug('letrus:fetch');

Promise.config({
  cancellation: true,
});

/**
 * Creates a 'fetch' function with extra configuration for Letrus and using the
 * serverUrl inside a closure.
 *
 * @param {Object} [options]
 * @param {String} [options.serverUrl] A host to prepend all '/' relative
 *   requests with, allows the frontend to be served from a different domain
 *   than the API
 * @param {EventEmitter} [options.statusEmitter] An event emitter to receive
 *   'error' events
 * @return {Function} fetch
 */

export default function configureFetch({serverUrl, statusEmitter} = {}) {
  /**
   * Customized version of the native WYSIWYG fetch function bundled in modern
   * browsers with extra error handling, retries and relative URL handling
   *
   * For full documentation see:
   * - The standard: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
   * - The library: https://github.github.io/fetch/
   *
   * Custom options are documented here.
   *
   * @param {String} url
   * @param {Object} [init]
   * @param {Boolean} [init.retryOnFailure] If specified allow retries
   */

  return function letrusFetch(url, init = {}) {
    /**
     * Handles errors and redirects on the response
     */

    async function onResponse(url, init, parsedUrl, res, statusEmitter) {
      const resUrl = nodeUrl.parse(res.url).pathname;
      if (url && resUrl !== parsedUrl.pathname) {
        const err = new Error(
          `Letrus API responded with Redirect (from ${JSON.stringify(
            url,
          )} to ${JSON.stringify(resUrl)})`,
        );
        err.status = 302;
        err.redirectRes = res;
        err.res = res;
        throw err;
      }

      if (res.status >= 400) {
        const err = new Error(`Letrus API failed with ${res.status}`);
        err.res = res;
        err.status = res.status;
        try {
          err.json = await res.json();
        } catch (_e) {} // eslint-disable-line no-empty

        // Broadcast authentication errors
        if (
          err.res &&
          err.res.status &&
          [403, 401].indexOf(err.res.status) !== -1
        ) {
          statusEmitter.emit('error', {
            url,
            init,
            error: err,
          });
        }

        // Broadcast not found error
        if (err.res && err.res.status && [404].indexOf(err.res.status) !== -1) {
          statusEmitter.emit('not-found', {
            url,
            init,
            error: err,
          });
        }

        throw err;
      }

      state.nErrors = 0;

      if (statusEmitter) {
        statusEmitter.emit('success', {
          url,
          init,
        });
      }

      return res;
    }

    function scheduleRetry(url, init, statusEmitter) {
      state.nErrors += 1;
      const waitingTime = getRetryTime();
      state.safeToRetryTime = new Date().getTime() + waitingTime * 1000;

      if (statusEmitter) {
        statusEmitter.emit('retry-scheduled', {
          now: new Date().getTime(),
          waitingTime,
          url,
          init,
        });
      }

      debug(
        'Scheduling retry ' +
          (init.method || 'GET') +
          ' ' +
          url +
          ' in ' +
          waitingTime +
          ' seconds',
      );

      return Promise.delay(waitingTime * 1000).then(function runRetry() {
        if (new Date().getTime() < state.safeToRetryTime) {
          const timeDiff = Math.abs(
            new Date().getTime() - state.safeToRetryTime,
          );
          return Promise.delay(timeDiff).then(runRetry);
        }

        if (statusEmitter) {
          statusEmitter.emit('retry', {
            url,
            init,
          });
        }

        debug('Retrying ' + (init.method || 'GET') + ' ' + url);
        return letrusFetch(url, init);
      });
    }

    const parsedUrl = nodeUrl.parse(url);
    if (serverUrl && !parsedUrl.host) {
      url = serverUrl + url;
    }

    if (process.env.IS_BROWSER) {
      nprogress.start();
    }

    // This would be the default, but makes the promise code simpler
    if (!init.method) {
      init.method = 'get';
    }
    init.method = init.method.toUpperCase();

    return Promise.cast(
      fetch(url, {
        credentials: 'include',
        ...init,
        headers: {
          // 'X-CSRFToken': Cookie.get('csrftoken'),
          ...(init.headers || {}),
        },
      }),
    )
      .timeout(1000 * 120)
      .finally(clearNprogress)
      .catch(err => {
        if (statusEmitter) {
          statusEmitter.emit('error', {
            url,
            init,
            error: err,
          });

          if (init.retryOnFailure) {
            return scheduleRetry(url, init, statusEmitter);
          }
        }

        throw err;
      })
      .then(res => onResponse(url, init, parsedUrl, res, statusEmitter));
  };
}

/**
 * Returns the No of seconds to wait before retrying a request.
 *
 * This uses exponential back-off with random wait times.
 *
 * The idea behind this is to schedule retries after an increasing delay and to
 * try to prevent clients from retrying requests at the same time, in the case
 * of degraded API service.
 *
 * This makes the client wait until the server is healthy and tries to prevent
 * clients from putting new servers down when there's a malfunction and the
 * server is getting back up.
 */

function getRetryTime() {
  const waitTime = Math.min(120, Math.pow(2, state.nErrors));
  return (Math.random() * waitTime) / 3 + waitTime;
}

/**
 * Fetching state
 */

export const state = {
  // No of retries that erroed
  nErrors: 0,
};

/**
 * Clear nprogress loader/loading-bar
 */

export function clearNprogress() {
  if (process.env.IS_BROWSER) {
    nprogress.done();
  }
}
