import React, {useState, useEffect} from 'react';
import debounce from 'lodash/debounce';
import cookie from 'react-cookies';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import configureFetch from './utils/configure-fetch';

// TODO: change this to use variable
const fetch = configureFetch({serverUrl: process.env.SERVER_URL});

function IuguCustomerTable() {
  const [query, setQuery] = useState('');
  const [alert, setAlert] = useState({message: '', type: '', pre: ''});
  const [hasLoaded, setLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    fetchSubscriptions();
  }, [query]);

  const fetchSubscriptions = async () => {
    setLoaded(false);
    let queryString = '';

    if (query) {
      queryString = `&query=${query}`;
    }

    const response = await fetch(
      `/api/v1/iugu_subscriptions/?limit=100&start=0${queryString}`,
      {
        headers: {
          'x-csrftoken': cookie.load('csrftoken'),
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    ).then(res => res.json());

    setSubscriptions(response);
    setLoaded(true);
  };

  const suspendCustomer = async customer_id => {
    try {
      await fetch(`/api/v1/iugu_customers/${customer_id}/run_downgrade/`, {
        headers: {
          'x-csrftoken': cookie.load('csrftoken'),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
      })
        .then(async res => {
          if (res.status >= 400) {
            throw await res.json();
          }

          setAlert({
            message: `A assinatura foi suspensa com sucesso para o usuário ${customer_id}`,
            type: 'success',
            pre: '',
          });
        })
        .then(fetchSubscriptions());
    } catch (err) {
      setAlert({
        message: `A assinatura não pôde ser suspensa:`,
        type: 'danger',
        pre: err.error,
      });
    }
  };

  const debounceQuery = debounce(text => setQuery(text), 1000);

  const handleQueryChange = queryArr => {
    const query = (queryArr[0] || {}).value || '';
    debounceQuery(query);
  };

  return (
    <div>
      <h1>Verificação de Usuários na Iugu</h1>

      <blockquote>
        <p>Tabela de alunos com assinatura na Iugu</p>
      </blockquote>

      {alert.message ? (
        <div className={`alert alert-${alert.type}`}>
          {alert.message}
          {alert.pre ? <pre>{alert.pre}</pre> : null}
        </div>
      ) : null}

      {hasLoaded ? (
        <ReactTable
          className="SubscriptionsTable"
          data={subscriptions.items}
          resolveData={subscriptions => subscriptions.items.map(row => row)}
          onFilteredChange={handleQueryChange}
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id].startsWith(filter.value)
          }
          columns={[
            {
              id: 'customer_id',
              Header: 'ID na Iugu',
              width: 300,
              accessor: subscriptions => subscriptions.customer_id,
              Cell: row => (
                <div className="text-center" style={{width: '100%'}}>
                  {row.value}
                </div>
              ),
            },
            {
              id: 'user_id',
              Header: 'Usuário',
              minWidth: 100,
              accessor: subscriptions => subscriptions.customer_name,
              filterMethod: (filter, row) =>
                row[filter.id].includes(filter.value),
              Cell: row => (
                <div className="text-center" style={{width: '100%'}}>
                  {row.value}
                </div>
              ),
            },
            {
              id: 'suspended',
              Header: 'Status',
              width: 100,
              accessor: subscriptions => subscriptions.suspended,
              Cell: row => (
                <div className="text-center" style={{width: '100%'}}>
                  {row.value ? 'Suspensa' : 'Ativa'}
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'suspended') {
                  return row[filter.id];
                }
                return !row[filter.id];
              },
              Filter: ({filter, onChange}) => (
                <select
                  onBlur={event => onChange(event.target.value)}
                  onChange={event => onChange(event.target.value)}
                  style={{width: '100%'}}
                  value={filter ? filter.value : 'all'}
                >
                  <option value="all">Todas</option>
                  <option value="suspended">Suspensas</option>
                  <option value="active">Ativas</option>
                </select>
              ),
            },
            {
              id: 'action_suspend',
              Header: 'Ação',
              width: 170,
              Cell: row =>
                !row.original.suspended && (
                  <button
                    className="btn btn-success"
                    modality="success"
                    onClick={() => suspendCustomer(row.original.customer_id)}
                  >
                    Suspender assinatura
                  </button>
                ),
              filterable: false,
              sortable: false,
              resizable: false,
            },
          ]}
        />
      ) : (
        <div>Carregando...</div>
      )}
    </div>
  );
}

export default IuguCustomerTable;
